import React from "react";
import '../Styling/Components.css';
import '../Styling/Home.css';
import Footer from './Footer';
import ProfilePic from '../Styling/Assets/ProfileImg.jpg';

export default function Home() {
    return (
        <div className="profile-container">
            <div className="profile-content-container">
                <div className="profile-image-container">
                    <img
                        src={ProfilePic}
                        className="d-inline-block profile-pic"
                        alt="ProfilePicture"
                    />
                </div>
                <div className="profile-description">
                    <h1>BRYAN NICHOLAS CARTER</h1>
                    <h3>actor • producer • podcast host</h3>
                    <p>
                        Bryan Carter, he/him is a Chicago based actor, producer and podcast host.
                    </p>
                    
                    <p>
                        Bryan is currently in rehearsals with <a  href= "https://www.avalanchetheatre.com/time-is-a-color" target="_blank" rel="noreferrer" style={{ color: 'black' }}>Avalanche Theatre</a> for their world premier of <i>Time Is A Color</i> and <i>the Color Is Blue</i> by <a  href="https://melaniecoffey.com/" target="_blank" rel="noreferrer" style={{ color: 'black' }}>Melanie Coffey</a> and is preparing to film scenes for the upcoming film, <i>The Love Factor</i> with Adam Lorraine Productions. 
                    </p>
                    
                    <p>
                        Recent credits include, <a href="https://www.chicagotribune.com/2025/01/15/review-kid-prince-and-pablo-by-lifeline-theatre-adds-hip-hop-score-to-a-classic-twain-tale/" style={{ color: 'black' }}><i>Kid Prince & Pablo</i></a> (Lifeline Theatre) <i>*Jeff Recommended</i>; <a  href="https://chicagoreader.com/performing-arts/theater/theater-review/bob-marleys-three-little-birds-young-peoples-theatre-of-chicago/" style={{ color: 'black' }}><i>Bob Marley’s Three Little Birds</i></a> (Young People’s Theatre of Chicago) <i>*Chicago Reader Recommended</i>; <i>The Left Hand of Darkness</i> (Chicago International Puppet Theatre); <i>Dontrell, Who Kissed the Sea</i> (Pegasus Theatre Chicago); <i>The Salon</i> (Black Ensemble Theatre); <i>Champion</i> (Lyric Opera of Chicago); as well as filming scenes for the upcoming feature film, <a  href="https://www.myracinecounty.com/uncategorized/2024/burlington-area-plays-host-to-feature-film/" style={{ color: 'black' }}><i>Raffle</i></a>(Two Scoops Productions). <a  href="/experience" style={{ color: 'black' }}>See Full Credits</a>
                    </p>
                    
                    <p>
                        In 2020 Carter received prominent recognition by the Kennedy Center American College Theatre Festival, being awarded the national honor for Best Actor in an Outstanding Role as Nat Turner in <i>Nat Turner in Jerusalem</i> (University of Wisconsin Oshkosh) directed by Merlaine Angwall.
                    </p>
                    
                    <p>
                        From 2020 to 2022, Bryan worked closely with Best-Selling Author and Personal Brand Strategist, Jessica Zweig, producing several live and virtual events as well as producing and guest hosting her podcast, <i>The SimplyBe.</i> Podcast, now the <i>Spiritual Hustler Podcast</i>.
                    </p>
                    
                    <p>
                        Carter is an alumnus of The School at Steppenwolf and holds his B.A. in Political Science.
                    </p>             
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
}
